import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Відкрийте для себе Optulafot
			</title>
			<meta name={"description"} content={"Створення спадщини вашого будинку"} />
			<meta property={"og:title"} content={"Про нас | Відкрийте для себе Optulafot"} />
			<meta property={"og:description"} content={"Створення спадщини вашого будинку"} />
			<meta property={"og:image"} content={"https://optulafot.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://optulafot.com/img/32576812.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://optulafot.com/img/32576812.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://optulafot.com/img/32576812.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://optulafot.com/img/32576812.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://optulafot.com/img/32576812.png"} />
			<meta name={"msapplication-TileImage"} content={"https://optulafot.com/img/32576812.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Створення спадщини вашого будинку
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Пориньте в серце Optulafot, місця, де ми віримо, що кожне укладання плитки - це не просто послуга, а ремесло, яке додає характеру та довговічності вашим приміщенням. Наша історія почалася з простої ідеї: поєднати позачасові техніки з сучасним дизайном, створюючи унікальні, довговічні та красиві простори для кожного клієнта. Наше прагнення до досконалості та інновацій зробило нас провідною компанією з укладання плитки, відомою своєю якістю, надійністю та художнім підходом.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://optulafot.com/img/6.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			sm-padding="40px 0 40px 0"
			sm-min-height="auto"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				margin="0px"
				font="--headline2"
				color="--dark"
				max-width="850px"
				width="50%"
				md-width="100%"
				md-margin="0px 0px 16px 0px"
			>
				Наш шлях
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="50%"
				md-width="100%"
				md-margin="16px 0px 0px 0px"
				sm-margin="16px 0px 32px 0px"
			>
				Компанія Optulafot була заснована на вірі в те, що кожен будинок має свою історію, а кожна підлога може стати шедевром. Завдяки десятиліттям колективного досвіду наша команда опанувала мистецтво укладання плитки, використовуючи як традиційні методи, так і сучасні досягнення. Наш шлях є свідченням нашої пристрасті до майстерності та прагнення надавати клієнтам неперевершений сервіс.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 0px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-margin="0px 0px 30px 0px"
			>
				<Image max-width="340px" src="https://optulafot.com/img/7.jpg" md-margin="0px 0px 20px 0px" />
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--dark" font="--headline2" md-margin="0px 0px 30px 0px">
					Чому Optulafot виділяється
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--base">
					Індивідуальний дизайн: Ми не просто укладаємо плитку - ми створюємо персоналізовані витвори мистецтва, що відображають ваш унікальний стиль.
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--base">
					Сталі практики: Наша прихильність до навколишнього середовища означає використання екологічно чистих матеріалів і методів, коли це можливо.
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base">
					Увага до деталей: Від початкового дизайну до останнього штриха, кожен крок виконується з точністю та ретельністю.
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base">
					Клієнтоорієнтований підхід: Ваше задоволення - наш головний пріоритет. Ми співпрацюємо з вами на кожному кроці, щоб втілити ваше бачення в життя.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});